:root {
    --deepblue: #173489;
    --font: "Poppins", sans-serif;
    --white: #fff;
  }
body{
    font-family: var( --font);
}
.mobile-left-menu{
  display: flex;
  align-items: center;
  gap: 15px;
}
.navbar{
    background-color: var(  --white);
    position: sticky !important;
    top: 0;
    padding-left: 60px;
    padding-right: 60px;
    z-index: 999;
}
.navbar .nav-item{
  cursor: pointer;
}
.navbar .cart-box{
  position: relative;
}
.navbar .cart-box .cart-add{
  position: absolute;
  left: 26px;
  top: -10px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: red;
  color: #fff;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

}
.nav-link{
    color: #000000 !important;
}
.navbar-brand{
    color: #000000 !important;
    font-size: 25px;
}
.no-search{
  display: flex ;
  justify-content: space-between;
}
.no-search .nav-right{
  display: flex;
  justify-content: end;
  padding-right: 10px;
}
.no-search .nav-right .nav-item{
  padding-left:25px ;
  padding-right: 25px;
}
/* ===================== footer-nav ======================= */
.footer-nav {
  z-index: 1;
  display: flex;
  justify-content: space-around;
  background-color: var(--deepblue) !important;
  padding: 10px;
  border-top: 1px solid #dee2e6;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  color: #fff;
}

.footer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-item i {
  font-size: 24px;
  margin-bottom: 5px;
}

.footer-item p {
  margin: 0;
  font-size: 12px;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
  /* Hover par dropdown show karne ke liye CSS */
  /* .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; 
  } */
    /* Ensure dropdown adjusts within the screen */
    .dropdown-menu {
      will-change: transform;
    }
  .cart .dropdown-toggle::after{
    display: none;
  }
  .nav-right{
    width: 50%;
    justify-content: space-between;
    color: #000000;
    padding-left: 20px;
    position: relative;
  }
  .veritcal-menu{

    position: absolute;
    top: -7px;
    right: 0;
    border-radius: 5px;
  }
  .veritcal-menu:hover{
     background-color: #ffffff8b;
  }
  /* Navbar.css */
.navbar-blue {
  background-color: #2874f0 !important;
  color: white !important;
  padding: .2rem 122px !important;
}
.navbar-blue .navbar-brand{
  color: var(--white) !important;
}
.navbar-blue .nav-right{
  color: #fff !important;
}
.navbar-blue .none{
  display: none;
} 
.navbar-blue .has-search .form-control{
  border-radius: 0 ;
}
.navbar-blue i{
  color: #fff;
}
.navbar-blue  .dropdown-menu i{
  color: #000;
}
.navbar-blue .dropAnimation::after{
  color: #fff;
}
/* ****************************** Animation *********************************** */
.dropAnimation{
  position: relative;
}

.dropAnimation::after {
  content: '\f078';
  position: absolute;
  top: 4px;
  right: -18px;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  display: inline-block;
  font-size: 12px;
  color: #333;
  cursor: pointer;
}

.dropAnimation:hover::after {
  transform: rotate(180deg); 
}
.dropAnimation:hover::before {
  content: '';
  position: absolute;
  left: -6px;
  top: -2px;
  background-color: #9a9a9a57;
  width: 93px;
  height: 100%;
  padding: 15px 43px 14px 14px;
  z-index: -1;
  border-radius: 5px;
}
.dropAnimation:hover .dropdown-menu {
  display: block;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  top: 26px;
}

.dropAnimation .dropdown-menu {
  display: none; 
  position: absolute; 
}
.dropAnimation .dropdown-menu{
  width: 245px;
}
.dropAnimation .dropdown-menu i{
  margin-right: 10px;
}
.dropAnimation .dropdown-menu .dropdown-item{
  font-size: 15px;
}
.drop-header{
  display: flex;
  padding: 10px;
  justify-content: space-between;
  border-bottom: 1px solid #aaaaaa6b;
}
.register-link{
  color: #173489;
  font-weight: 700;
}
/* .verMenuAnimation  .dropdown-menu[data-bs-popper] {
  top: 32px;
  left: 100%;
  left: auto;
  margin-top: 0;
  margin-right: .125rem;
} */
.verMenuAnimation {
  position: relative; /* Make this the reference point for absolute positioning */
}
.verMenuAnimation .dropdown-menu i{
  margin-right: 10px;
}
.verMenuAnimation .dropdown-menu .dropdown-item{
  font-size: 15px;
}
.verMenuAnimation .dropdown-menu {
  display: none; 
  position: absolute; 
  top: 100%; 
  left: -174px;
  z-index: 1000; 
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  background-color: white; 
  border-radius: 4px; 
}

/* Show the dropdown menu on hover */
.verMenuAnimation:hover .dropdown-menu {
  display: block; /* Show the dropdown menu when hovering */
}

/* Optional styles for the dropdown items */
.verMenuAnimation .dropdown-item {
  padding: 10px; /* Adjust padding as needed */
  white-space: nowrap; /* Prevent text wrapping */
}

/* Adjustments for position if using data-bs-popper */
.verMenuAnimation .dropdown-menu[data-bs-popper] {
  margin: 0; /* No extra margin */
}

/* ****************************** Animation *********************************** */
@media screen and (max-width: 767px) {
  .navbar-blue {
    padding: .2rem 0px !important;
  }
  .has-search{
    margin-top: 2px;
    background-color: rgb(225 235 255);
    padding: 3px;
    border-radius: 10px;
  }
   .has-search input{
      border: none;
      background: transparent;
    
   }
   .has-search input::placeholder{
    font-size: 14px;
   }
   .nav-right {
    width: auto;
    justify-content: end;
    color: #000000;
    padding-left: 0;
    display: flex;
    align-items: center;

}
.navbar{
  justify-content: space-between;
}
.mobile-left-menu{
  display: flex;
  align-items: center;
  padding-left: 13px;
  gap: 15px;
}
.mid-cart{
  margin-left: 20px;
  margin-right: 20px;
}
.dropAnimation::after{
  display: none;
}
.dropAnimation::before{
  display: none;
}
}