.whishlist .card{
    font-family: var(--poppins-font);
    padding: 10px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}
.whishlist .card .card-title{
    font-size: 15px;
}
.whishlist .card .card-text{
    margin-bottom: 5px;
    font-size: 14px;
    color: var(--main-text-color);
}
.whishlist .card-btn .btn:focus{
    box-shadow: none;
}
.whishlist .card-btn{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
}
@media screen and (max-width: 767px) {
    .whishlist .card-btn{
        justify-content: center;
    }
    .whishlist .card-btn .btn{
        margin-bottom: 5px;
        width: 100%;
        font-size: 14px;
    }
    .whishlist .card .card-title{
        font-size: 14px;
    }
}