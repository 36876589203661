.about .card{
    border: none;
    border-bottom: var( --bottom-border);
    margin-bottom: 10px;
    border-radius: 0;
    padding-bottom: 10px;
}
.about-card-two .card{
    border: none;
}
.about-card-two .card-title{
    font-size: 15px;
    text-align: start;
    padding:0 20px;
}
.about-card-two .card-text{
    padding:0 20px;
    font-size: 14px;
}
@media screen and (max-width: 767px){
    .about-card-two .card-title{
        padding: 0;
    }
    .about-card-two .card-text{
        padding: 0;
    }
}