.ul-profile{
    list-style: none;
    padding-left: 0;
}
.ul-profile li{
    padding: 10px 0px 10px 45px;
    font-size: 14px;
    transition: 0.1s;
}
.ul-profile li:hover{
    background-color: #eee;
    color: var( --primary-color);
    cursor: pointer;
}
.ul-profile li.active {
    background-color: #eee; /* Active background color */
    color: var(--primary-color); /* Active text color */
    font-weight: 600;
}