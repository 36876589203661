.side-bar .offcanvas-start{
     width: 290px;
}
/* .modal-backdrop.fade.show{
    border: 1px solid red;
    display: none;
} */

.side-bar .offcanvas-header{
      background-color: var(--btn-bg-color);
}
.side-bar .offcanvas-header h5{
     display: flex;
     align-items: center;
     color: #fff;
     font-size: 23px;
}
.side-bar .offcanvas-header h5 i{
    margin-right: 20px;
}
.side-bar .side-bar-list{
    list-style: none;
    padding-left: 0;
}
.side-bar .side-bar-list li{
    margin-bottom: 15px;
    font-size: 18px;
    font-family: var(--poppins-font);
    font-weight: 500;
    color: #000;
}
.side-bar .side-bar-list li i{
    margin-right:8px ;
    font-size: 15px;
    color: #000;
}
.side-bar .ul2 li {
      margin-bottom: 15px;
      color: var(--main-text-color);
}