.giftCard .card-infox{
    border: none;
}
.giftCard .card-infox h5{
    color:#212121;
    text-transform: uppercase;
}
.giftCard .card-infox p{
      color: var(--main-text-color);
}
@media screen and (max-width : 767px) {
    .giftCard .card h5{
        font-size: 14px;
    }
}