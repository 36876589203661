/* styles.css */
.product-btn-list h2 {
    margin-bottom: 0;
    font-size: 16px;
    margin-right: 10px;
}

.product-btn-list {
    display: flex;
    align-items: center;
    margin-right: 10px;
    padding: 10px 0;
}

.sort-button {
    border: 2px solid transparent;
    background-color: white;
    color: black;
    padding: 5px 10px;
    margin-right: 10px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s;
    /* Hover effect ke liye transition */
}

.sort-button.active {
    border-color: #f0f0f0;
    color: #878787;
}

.sort-button:hover {
    background-color: #f0f0f0;
    /* Hover par background color */
}

.product-title {
    color: #878787;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 600;
}

.product-disc {
    font-size: 14px;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* Limits text to 1 line */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.discount {
    color: green;
    font-size: 14px;
}

.orgprice {
    text-decoration: line-through;
    font-size: 15px;
    color: #878787;
}

.size {
    font-size: 14px;
    color: #878787;
}

.product-list-containet .card {
    position: relative;
    border: 1px solid #e9e9e9d4;
    cursor: pointer;
}

.product-list-containet .card {
    transition: transform 0.3s ease;
    overflow: hidden;
}

.product-list-containet .card:hover img {
    transform: scale(1.02);
    /* Scale the image to 110% */
    transition: transform 0.3s ease;
    /* Smooth transition */
}

.product-list-containet .card .fa-heart {
    position: absolute;
    right: 9px;
    top: 14px;
    color: #e4e4e4;
    font-size: 24px;
    z-index: 1;
    transition: transform 0.3s ease;
}

.product-list-containet .card .fa-heart:hover {
    transform: scale(1.02);
    /* Scale the image to 110% */
    transition: transform 0.3s ease;
    /* Smooth transition */
}

@media screen and (max-width:767px) {
    .product-btn-list {
        flex-wrap: wrap;
        align-items: center;
        margin-right: 10px;
        padding: 10px 0;
    }

    .custom-padding {
        padding: 1px;
    }
}

/* 
.product-img {

} */


.product-img2 {
    width: 100%;
    /* Makes the container take up the full width */
    height: 0;
    /* Initially set the height to 0 */
    padding-top: 100%;
    /* Creates a 1:1 aspect ratio by using padding (height = width) */
    position: relative;
    /* Allows for positioning of child elements */
    overflow: hidden;
    /* Ensures the image is cropped to fit the box */
}

.product-img2 img {
    position: absolute;
    /* Position the image inside the container */
    top: 0;
    /* Align the image to the top */
    left: 0;
    /* Align the image to the left */
    width: 100%;
    /* Make the image take up the full width */
    height: 100%;
    /* Make the image take up the full height */
    object-fit: cover;
    /* Ensures the image covers the 1x1 box */
}

.img-fluid {
    max-width: 100%;
    /* Make the image responsive */
    height: auto;
    /* Maintain aspect ratio for the image */
}


.product-img {
    width: 100%;
    /* Makes the container take up the full width */
    height: 0;
    /* Initially set the height to 0 */
    padding-top: 100%;
    /* Creates a 1:1 aspect ratio by using padding (height = width) */
    position: relative;
    /* Allows for positioning of child elements */
    overflow: hidden;
    /* Ensures the image is contained within the box */
}

.product-img img {
    position: absolute;
    /* Position the image inside the container */
    top: 0;
    /* Align the image to the top */
    left: 0;
    /* Align the image to the left */
    width: 100%;
    /* Make the image take up the full width */
    height: 100%;
    /* Make the image take up the full height */
    object-fit: contain;
    /* Ensures the image scales to fit without cropping */
    margin: auto;
    /* Center the image within the container */
}

.accordion ul li a {
    font-size: 15px;
    color: #878787;
    margin-bottom: 5px;
}

.accordion-body {
    padding: 0.2rem 0.5rem;
}

.accordion-button {
    padding: 0.8rem 0.5rem;
}