/* *************************** page css ************************* */
.pages-conatiner{
    padding: 26px 29px;
    margin: 0 120px;
  }
  .pages-conatiner .content{
    margin-bottom: 30px;
  }
  .pages-conatiner ul{
    padding-left: 0;
    list-style: none;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: var( --poppins-font);
  }
 .pages-conatiner h3{
    font-size: 18px ;
    margin-bottom: 18px;
    font-family: var( --poppins-font);
  }
  .pages-conatiner h4{
    font-style: 20px !important;
  }
  .pages-conatiner p{
    font-size: 14px;
    font-family: var( --poppins-font);
  
  }
  .pages-conatiner table {
    width: 100%;
    border-collapse: collapse; 
  }
  
  .pages-conatiner th, td {
    border: 1px solid #dddddd;
    text-align: left; 
    padding: 8px; 
    font-size: 13px;

}
  
.pages-conatiner th {
    background-color: #f2f2f2; /* Light gray background for header */
  }
  @media screen and (max-width: 767px) {
    .pages-conatiner{
      padding: 26px 15px;
      margin: 0 0;
    }
  }
  /* *************************** page css ************************* */