:root {
    --deepblue: #173489;
    --font: "Poppins", sans-serif;
    --white: #fff;
  }

 body{
    font-family: var( --font);
    background-color: #f1f3f6;
 }
 /* .tee{
  
} */
.top-scroll-nav{
    display: flex;
    align-items: center;
    /* border: 1px solid; */
    padding: 30px 0 0 0;
       background-color: var(--white);
    justify-content: center;
}
.section-padding-tb{
    padding-top: 50px;
    padding-bottom: 10px;
}
.top-scroll-nav .circle{
    background-color: var(--deepblue);
    height: 100px;
    width: 100px;
    border-radius: 50%;
    color: #fff;
    display: flex;
    font-size: 14px;
    justify-content: center;
    align-items: center;
}
.sell-buy{
    text-align: center;
    /* border: 1px solid red; */
    padding: 10px;
    margin-right: 15px;
}
.sell-buy .sellAll{
         color: black;
         font-weight: 500;
}
.rounded-div img{
      width: 120px;
      height: 120px;
      border-radius: 50%;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.name-label{
    font-size: 15px;
    text-align: center;
    font-weight: 500;
}
.scroll-list{
    display: flex;
    gap: 25px;
    overflow-y: scroll;
}
::-webkit-scrollbar{
    display: none;
}
.banner{
    padding: 30px 0px;
}
.banner img{
    width: 100%;
    border-radius: 10px;
}
.tool-section .card{
    text-align: center;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 400px;
    padding: 0px 24px 27px 27px;
    margin-bottom: 20px;
    border: none;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.tool-section .card img{
    width: 100px;
}
.tool-section .card .name-label{
    position: absolute;
    bottom: 22px;

}

.see-btn{
    background-color: var(--deepblue);
    color: #fff;
    text-decoration: none;
    padding: 5px 10px;
}
.card-banner{
    padding: 10px;
}
.card-banner h5{
    font-size: 18px;
}
.card-banner img{
     width: 100%;
     height: 455px;
}
.card-banner p{
     margin-bottom: 0;
     font-size: 14px;
}
.card-banner-inner{
    padding-top: 20px;
}
.card-banner-inner .rating {
    margin-top: 10px;
}
.card-banner-inner .rating span{
    background-color: green;
    width: 100px !important;
    padding:5px 10px ;
    color: #fff;
    margin-right: 10px;
}
.product-section .card{
    margin-bottom: 20px;
    border: none;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    overflow: hidden;
    font-size: 15px;
}
.product-section .card .card-body{
    padding-top: 7px;
}
.product-section .card .product-title{
       margin-bottom: 5px;
}
.product-price{
    margin-bottom: 5px;
}
.product-price span{
    color: green;
}
.product-section .card .mrp sup{
    color: red;
}
/* .product-section .card .mrp{
   
} */
.product-section .card .location {
        color: #919191;
}
.product-section .card .location i{
    color: red;
    margin-right: 5px;
}




/* ============================== category-nav  ================================ */
@media screen and (max-width: 767px) {
    p{
        font-size: 15px;
    }
    .navbar{
        padding-left: 0;
        padding-right: 0;
    }
    .top-scroll-nav .circle{
        height: 70px;
        width: 70px;
        font-size: 14px;
    }
    .sell-buy .sellAll {
        color: black;
        font-weight: 400;
        margin-top: 5px;
        font-size: 13px;
    }
    .name-label {
        font-size: 12px;
        text-align: center;
        margin-top: 5px;
        font-weight: 500;
    }
    .rounded-div img {
        width: 70px;
        height: 70px;
    }
    .tool-section .card {
        height: 102px;
        width: 124px;
        padding: 0px 0px 27px 0;
        margin-bottom: 20px;
    }
    .tool-section .card img {
        width: 40px;
    }
    .tool-section .card .name-label {
        position: absolute;
        bottom: 4px;
        font-size: 10px;
    }
    .category-nav{
        overflow: scroll;
    }
    
}
.dropdown-hover:hover>.dropdown-menu-hover {
    display: block;
  }
  
  .dropdown-hover>.dropdown-toggle:active {
    pointer-events: none;
  }
  @media only screen and (max-width: 414px) {
    /* Styles for screens smaller than or equal to 414px */
    .product_300 .card {
        width: 9rem;
        padding: 5px;
        margin-bottom: 20px;
    }
  }
  /* @media only screen and (max-width: 375px) {
    .product_300 .card {
        width: 8rem;
        padding: 5px;
        margin-bottom: 20px;
    }
  } */

  .container-padding{
    padding: 10px;
}
.small-long-deatails{
    line-height: 1.45;
    font-size: 12px;
    color: #878787;
}
.breadcrumb{
    font-size: 12px;
}
.breadcrumb a{
    color: #878787;
    text-decoration: none;
}
.h5{
    font-size: 16px;
    margin-bottom: 0;
}
.products-showing{
    color: #878787;
    font-size: 12px;
}
.custom-flex{
    display: flex;
    align-items: center;
    gap: 10px;
}