.filter-headline{
    border-bottom: 1px solid #d9d9d9b0;
    padding: 15px;
    color: #212121;
    margin-bottom: 5px;
    font-size: 22px;
}
.price-section h3{
    font-size: 15px;
    text-transform: uppercase;
    color: #212121;
}
.accordion-item,
.accordion-header,
.accordion-button {
  border: none;
  box-shadow: none; /* Shadow bhi hatane ke liye */
}
.accordion-button:focus{
    box-shadow: none;
    border: none;
}
.accordion-button::after{
    margin-left: 10px;
}
.rating-section,
.brand-secntion,
.price-section,
.category-filter{
    padding: 10px;
    border-bottom: 1px solid #d9d9d9b0;
    margin-bottom: 20px;
}

.min-price{
    padding: 5px 12px;
    border: 1px solid #d9d9d9b0;
    outline: none;
    font-size: 15px;
    margin-top: 10px;
    
}
.accordion-button:not(.collapsed) {
    color: #212121;
    background-color: transparent;
    box-shadow: none;
}
/* .accordion-button:not(.collapsed)::after {
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
    transform: rotate(-180deg);
} */
.accordion-button::after {
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
     background-size: 1rem;
    transition: transform .2s ease-in-out;
}
.accordion-list .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.accordion-list .accordion-button {
    display: flex ;
    justify-content: space-between ;
    border-top: 1px solid #d9d9d9b0;
    padding-left: 5px;
}
 .accordion-button{
    text-align: start;
    padding-left: 0;
    font-size: 12px;
    font-weight: 600;
    color: #212121;
    text-transform: uppercase;
}
.accordion-body{
   padding-top: 0;
   padding-left: 5px;
}
.accordion ul{
    list-style: none;
    padding: 5px;
}
.accordion ul li{
    font-size: 15px;
    color: #878787;
    margin-bottom: 5px;
}
.checkbox{
    border: 1px solid #d9d9d9b0;
}
label{
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 10px;
    color: #878787;
}