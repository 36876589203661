.category-nav{
  
    display: flex;
    justify-content: center;
    padding: 1px 10px;
    background-color: #ffff;
}
.category-nav .category-img{
   text-align: center;
}
.category-nav .category-img img{
   width: 64px;
   height: 64px;
}
.level-same{
   position: relative;
   padding: 10px;
 }
 /* Main dropdown opens on hover */
.category-nav .level-same:hover > .dropdown-menu {
     display: block;
     margin-top: 0;
 }
 .level-same .nav-link{
    font-size: 15px;
 }
 /* Submenu handling */
 /* .dropdown-submenu {
     position: relative;
 } */

 /* Submenu aligned to the right and top of the main dropdown */
 .level-same .dropdown-submenu .dropdown-menu {
     display: none;
     position: absolute;
     top: 0; /* Align all submenu with the main dropdown top */
     left: 100%; /* Submenu aligned to the right of parent */
     margin-top: 0; /* No margin to adjust */
 }

 /* Show submenu on hover */
.level-same .dropdown-submenu:hover > .dropdown-menu {
     display: block;
 }

 /* Basic styling for hover */
.level-same .dropdown-item {
     padding: 10px 20px;
 }

 /* Dropdown hover effect */
 .level-same .dropdown-item:hover {
     background-color: #f8f9fa;
     color: #000;
 }
 .allCategories .card-body{
    padding: 1px;
 }
 .allCategories .card h5{
    font-size: 14px;
 }



 a {
    text-decoration: none;
 }