  .become-seller{
    background-color: var(--white);
  }
  .become-seller p{
    color: var(--fadedark);
    line-height: 1.7rem;
  }
  .become-seller ul li{
     margin-bottom: 10px;
  }
  .headline-seller2{
    padding: 10px 0;
  }
  .headlineb {
    cursor: pointer;
    padding: 10px;
    transition: background-color 0.3s;
  }
  
  .headlineb:hover {
    background-color: #f0f0f0;
  }
  
  /* .active {
    background-color: #d3d3d3;
  } */
  
  .right-column {
    padding: 20px;
  }
  
  .content-section {
    /* margin-bottom: 10px;  */
  }
  .become-seller .card {
    padding: 20px;
    border-radius: 15px;
    border: none;
  }
  .become-seller .card .step{
    display: flex;
    align-items: center;
  }
  .become-seller .card .step i{
    margin-right: 10px;
  }
  .tab1 tr th{
    padding: 10px;
  }
  .tab1 tr td{
    font-size: 14px;
    border: 1px solid rgb(202, 202, 202);
  }
  .content-section{
    padding-top: 20px;
    padding-bottom: 20px;
  }