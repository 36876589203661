
  .product-short-detail{
    font-size: 15px;
    color: #212121;
  }
  .price-section{
    font-size: 25px;
  }
  .price-section s{
    font-size: 19px;
  }
  .rating{
    color: green;
  }
  .offer-list{
    list-style: none;
    padding-left: 0;
    margin-top: 10px;
  }
  .offer-list li{
    position: relative;
    padding-left: 23px;
    font-size: 14px;
    color: #212121;
    margin-bottom: 10px;
  }
  .offer-list li::after{
    content: '';
    position: absolute;
    left: -3px;
    top: 3px;
    background-image: url(https://rukminim2.flixcart.com/www/36/36/promos/06/09/2016/c22c9fc4-0555-4460-8401-bf5c28d7ba29.png?q=90);
    background-repeat: no-repeat;
    background-size: cover;
    width: 17px;
    height: 17px;

  }

  .product-color{
    display: flex;
    gap: 10px;
  }
   .product-color .color-container{
    padding: 5px;
    background-color: #fff;
   }
 .product-color .color-container img{
      width: 80px;
      cursor: pointer;
 }
 .product-size-list{
    display: flex;
    gap: 5px;
 }
 .size-container{
    width: 30px;
    height: 30px;
    border-radius: 2px;
    border: 1px solid #d9d9d9b0;
    display: flex;
    justify-content: center;
    align-items: center;
 }
 .size-container:hover{
    border: 1px solid blue;
 }
 .accordion-item,
 .accordion-header,
 .accordion-button {
   border: none;
   box-shadow: none;
   /* Shadow bhi hatane ke liye */
 }

 .accordion-button:focus {
   box-shadow: none;
   border: none;
 }

 .accordion-button:not(.collapsed) {
   color: #212121;
   background-color: transparent;
   box-shadow: none;
 }

 .accordion-button::after {
   background-image: none;
 }

 .accordion-button:not(.collapsed)::after {
   background-image: none;
 }

 /* Styling for plus/minus icon */
 .accordion-button::after {
   content: '\002B';
   /* Plus icon */
   font-size: 20px;
   transition: transform 0.3s ease;
 }

 .accordion-button:not(.collapsed)::after {
   content: '\2212';
   /* Minus icon */
 }

 .accordion-button {
   display: flex;
   justify-content: space-between;
   align-items: center;
   font-weight: bold;
 }
 .product-info-label {
         font-weight: bold;
     }

     .product-info-value {
         color: #000;
     }

     .product-info-row {
         padding: 5px 0;
     }
     .scroll-section{
        /* border: 1px solid; */
        height: 750px;
        overflow-y: scroll;
        background-color: #fff;
     }
     .product-image-container{
      padding: 20px;
     }
     .buy-section-btn{
      display: flex;
      gap: 5px;
      margin-top: 10px;
     }
     .btn-cart,
.btn-buy{
  background-color: #ff9f00;
  width: 100%;
  border-radius: 0;
  color: #fff;
  padding: 15px;
}