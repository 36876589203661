
:root {
  --btn-bg-color:#fb641b;
  --main-bg-color: #f0f0f0;
  --main-font: 'Arial', sans-serif; 
  --main-text-color: #878787; 
 --white-color:#fff;
 --primary-color:#2874f0;
 --poppins-font: "Poppins", sans-serif;
 --bottom-border :1px solid #d9d9d9b0;
 --green: green;
 --fadedark: #333333;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  --poppins-font: "Poppins", sans-serif;
  color: #212121;
}

ul li{
  font-family: var(--poppins-font);
}
.ul-one{
   list-style: none;
   padding: 0;
}
.ul-one li{
  margin-bottom: 10px;
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
/* :root{
    color: var;
} */
 .card-title{
  font-family: var(--poppins-font);
 }
 .card-text{
  font-family: var(--poppins-font);
  font-size: 15px;
 }
.headline{
  font-size: 22px;
  padding-left: 12px;
  color: #515151;
  }
  .headline{
    font-family: var(--font);
}
.headline-icon{
   display: flex;
   gap: 10px;
   align-items: center;
   padding: 0px 20px;
   color: var(--main-text-color);
   font-weight: 500;
   font-size: 18px;   
   margin-bottom: 19px;
   font-family: var( --poppins-font);
   cursor: pointer;
}
.headline-icon i{
  font-size: 19px;
  color: var(--primary-color);

}
.bottom-border{
  border-bottom: var(--bottom-border);
  padding-bottom:10px;
}
.link{
  text-decoration: none;
  background-color: transparent;
}
/* ********************************************* bootstrap ********************************************* */
   .form-control:focus{
    box-shadow: none;
   }
   .btn:focus{
    box-shadow: 0;
   }
/* ********************************************* bootstrap ********************************************* */


/* ********************************************* section css ********************************************* */
.white-section{
  background-color: var(--white-color);
  }
 .padding-20-5{
  padding: 20px 5px;
 }
 .padding-20-15{
  padding: 20px 15px;
 }
.section-padding{
  padding: 20px;
}
.section-margin-top-bottom{
  margin-top: 20px;
  margin-bottom: 20px;
}
.section-margin-top{
  margin-top: 8px;
}
.section-margin-bottom{
  margin-bottom: 50px;
}
.col-right-space{
   padding-right: 8px;
}
.font-13{
    font-size: 13px;
    font-family: var(--poppins-font);
}
/* ********************************************* section css ********************************************* */



/* ***************************** ui material searchInput ********************************************** */
.searchInput{
         width: 100%;
          border-radius: 0;
}
/* ***************************** ui material searchInput ********************************************** */



/* ***************************** color ********************************************** */
.green{
  color: var(--green);
}


.black {
  color: #000
}

.bold {
  font-weight: bold;
}