.customer-feedback {
    font-weight: bold;
}
.feedback-item {
    margin-bottom: 10px;
}
.customer-images img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 10px;
}
.reaction-row {
    /* display: flex; */
    /* align-items: center; */
    margin-top: 20px;
    /* display: flex;
    justify-content: space-between; */
    margin-top: 50px;
    /* border: 1px solid; */
    position: relative;
}
.reaction-row span {
    font-size: 1rem;
    margin-right: 10px;
}
.reaction-row i {
    font-size: 1.2rem;
    cursor: pointer;
    margin-right: 5px;
    color: gray;

}
.review-like-dislike{
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    right: 0;
    bottom: 0;
}
.buyer-container{
    /* border: 1px solid; */
    padding: 5px 20px;
}
.buyer-container .buyer-name , .buyer-certied{
    font-size: 14px;
    color: var(--main-text-color);
}
.buyer-container  .buyer-certied i{
    color: green;
    font-size: 10px;
}
.islicke,
.like{
    display: flex;
    align-items: center;
}
.customer-feedback{
  display: flex;

}
.feedback-item{
  background-color:#eee;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  color: #212121;
  padding: 5px 7px;

}
.review-btn-container{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}
.qustions .faq-qustion{
    font-size: 15px;
    margin-left: 10px;
    color: #212121;
}