.cart-contnet .empty-container{
    display: flex;
    justify-content: center;
     padding-bottom: 50px;
}

.empty-container .cart-img{
    filter: drop-shadow(1px 1px 1px rgba(112, 112, 112, 0.2));
}
.empty-container .cart-login-btn{
    background-color: #fb641b;
    width: 100%;
    color: #fff;
    border-radius: 0;
}
.product-details{
    border: none;
    padding: 10px;
    margin-bottom: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.product-details .card-body{
    display: flex;
    align-items: center;
    gap: 20px;
    padding-bottom: 20px;
}
.product-details .card-footer{
    background-color: var(--white);
    padding-top: 20px;
    padding-bottom: 20px;
}
.product-details img{
    width: 150px;
}
.product-details h5{
   margin-bottom: 2px;
}
.product-details small{
    color: var(--main-text-color);
}
.product-details .price-cart{
    display: flex;
    gap: 10px;
    align-items: center;
}
.product-details .current-price{
    font-size: 18px;
}
.quantity-selector{
    display: flex;
    align-items: center;
    gap: 20px;
}
.price-details{
    padding: 20px;
}
.price-details .price-item{
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.price-details .total-price{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-weight: 700;
}
.save-offfer{
    color: var(--green);
}
.price-col{
    height: 446px;
}
.product-cart-col{
    height: 700px;
    overflow: scroll;
}