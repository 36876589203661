.login-Container{
    display: flex;
    justify-content: center;
    padding: 0px;;
}
.login-form{
    /* border: 1px solid; */
    display: flex;
    height: 550px;
    background-color: #fff;
}
.login-left-content{
    background-color: #3f51b5; /* Blue background */
    position: relative;
    padding: 20px 20px;
    width: 335px;
}
.login-left-content p{
    color: #eee;
    margin-bottom: 0;
    font-size: 15px;
}
.login-right-content{
   padding: 20px;
   position: relative;
   width: 63%;
}
.left-column {
    background-color: #3f51b5; /* Blue background */
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
    position: relative;
  }
  
  .text-content {
    margin-bottom: auto; /* Space above the image */
  }
  .register-info{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%) ;
    padding: 10px;
  }
  .bottom-image {
    width: 200px;         /* Set the desired width */
    height: auto;        /* Maintain aspect ratio */
    position: absolute;   /* Positioning the image absolutely */
    bottom: -10px;       /* Position from the bottom */
    left: 50%;           /* Position the left edge at 50% of the container */
    transform: translateX(-50%); /* Center the image horizontally */
  }
  .small{
    font-size: 14px !important;
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .btn-login{
    background-color: var( --btn-bg-color) !important;
    padding: 12px !important;
    border-radius: 0 !important;
  }
  @media screen and (max-width: 767px) {
    .login-left-content{
        display: none;
    }
    .login-right-content{
        width: 100%;
    }
  }