
   .footer {
    background-color: #212121;
    padding: 40px;
    font-size: 14px;
    position: relative;
  }
  
  .footer-section {
      padding: 20px 0px;
  }
  .left-footer{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
  }
  .footer-links {
    flex: 1;
    /* margin: 0 10px; */
  }
  
  .footer-links h3 {
    color: #b6b6b6;
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  .footer-links ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-links ul li {
    margin-bottom: 8px;
  }
  
  .footer-links ul li a {
    color: #ffffff;
    text-decoration: none;
    font-size: 14px;
  }
  
  .footer-links ul li a:hover {
    text-decoration: underline;
  }
  
  .payment-methods {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    color: #ffffff;
  }
  
  .payment-methods img {
    width: 40px;
    margin-right: 10px;
    border: 1px solid #b6b6b6;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .app-download {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
  }
  
  .app-download img {
    height: 40px;
    margin-right: 10px;
  }
  .footer-legal{
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: 0;
    left: 0;
  }
  .footer-legal {
    margin-top: 20px;
    text-align: center;
    color: #b6b6b6;
  }
  /* .footer-links{
    padding-left: 50px;
  } */

  .right-side-border{
    position: relative;
  }
 .right-side-border::after{
  content: '';
  position: absolute;
  top: 6px;
  left: -38px;
  width: 1px;
  height: 180px;
  background-color: #b6b6b654;

 }
  @media screen and (max-width: 767px ) {
  
    .footer-section {
        flex-wrap: wrap;
      
    }
    .footer {
        padding: 40px 20px;
    }
    .footer-links{
        padding: 0;
    }
    .footer-links h3{
        font-size: 19px;
    }
    .footer-links{
        margin-bottom: 20px;
    }
  }