.carousel-control-prev,
  .carousel-control-next {
    width: 35px; /* Width set to 35px */
    height: 70px; /* Height set to 70px */
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Adjust for exact vertical center */
    background-color: #000000 ; /* Background color set to white */
    border: none; /* Remove border if any */
    border-radius: 5px;
    opacity: 1;
  }
  
  /* Change icon color to black */
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-color: black; /* Icon background color */
    border-radius: 50%; /* Optional: Rounded icon */
  }
  
  /* Specific positioning for prev and next buttons */
  .carousel-control-prev {
    left: 0; /* Align to the left */
  }
  
  .carousel-control-next {
    right: 0; /* Align to the right */
  }
  