
.categories-nav {
  list-style: none;
}
.categories-nav{
  display: flex ;
  padding-left: 122px;
  padding-right: 122px;
}
.categories-nav .nav-link{
  font-size: 14px;
}
.shadow-bottom{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}



.categories-nav .dropdown {
  position: static !important; 
}

/* Make dropdown-menu full width and aligned with navbar */
.categories-nav .dropdown-menu {
  position: absolute;
  top: 100%; /* Right below the navbar */
  left: 0; /* Aligns the dropdown menu with the navbar's left edge */
  right: 0;
  width: 100%; /* Adjust to make full-width like the navbar */
  margin: 0; /* Ensure no extra margins */
  background-color: #fff; /* Set your desired background color */
}
/* @media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: .5rem;
      padding-left: .5rem;
      font-size: 14px;
  }
} */


