/* .contact-page{
     padding: 0 125px;
} */

.contact-right {
    padding-left: 50px;
}
.contact-right ul{
    list-style: none;
    padding-left: 0;
    margin-top: 10px;
 
}
.contact-right ul li{
    margin-bottom: 10px;
    font-size: 15px;
    font-family: var(--poppins-font);
}
.social-links a{
    text-decoration: none;
    font-size: 15px;
}
@media screen and (max-width: 767px) {
    .contact-page{
        padding: 0 ;
   }
   .contact-right{
    padding-left: 0;
   }
}