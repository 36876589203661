.product_300{
    padding-bottom: 20px;
    padding-top: 20px;
   
}
.product_300 .owl-theme{
background-color: #fff;

}
.right-chev{
background-color: blue;
width: 25px;
height: 25px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
color: #fff;
cursor: pointer;
}

.owl-theme .item {
margin: 10px; /* Yahan par apne requirement ke hisab se gap set karein */
}
/* Custom styles for Owl Carousel navigation */
.owl-theme .owl-nav {
display: flex;
justify-content: space-between; /* Distribute space between buttons */
/* position: relative;  */
width: 100%; /* Full width of the carousel */
height: 100%;
top: 0;
}

.owl-theme .owl-nav .owl-prev,
.owl-theme .owl-nav .owl-next {
position: absolute; /* Position buttons absolutely */
top: 50%; /* Center vertically */
transform: translateY(-50%); /* Adjust for exact center */
background-color: rgba(255, 255, 255, 0.8); /* Optional background */
border: 1px solid #ccc; /* Optional border */
border-radius: 50%; /* Make buttons round */
width: 40px; /* Button width */
height: 40px; /* Button height */
display: flex;
align-items: center;
justify-content: center;
cursor: pointer; /* Pointer on hover */
}

/* Position left button */
.owl-theme .owl-nav .owl-prev {
left: 10px; /* Position to left */
}

/* Position right button */
.owl-theme .owl-nav .owl-next {
right: 10px; /* Position to right */
}
.owl-theme .owl-nav [class*='owl-'] {
color: #555555 !important;
font-size: 14px;
margin: 5px;
padding: 4px 7px;
background: #D6D6D6;
display: inline-block;
cursor: pointer;
border-radius: 3px;
height: 90px;
width: 35px;
}
.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
background: rgb(255, 255, 255) !important;
color: inherit;
border: none;
padding: 0 !important;
font: inherit;
/* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}
.owl-theme .owl-nav .disabled {
display: none !important;
cursor: default;
}
.owl-theme .owl-nav .owl-prev {
left: -9px;
}
.owl-theme .owl-nav .owl-next {
right: -9px;
}
.owl-stage {
    padding-left: 0 !important;  /* Left se padding remove */
  }